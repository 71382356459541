import { Outlet } from 'react-router-dom';

import Footer from "../components/Footer";
import styles from "./MainLayout.module.scss"

const MiniLayout = () => {
    return (
        <>
            <div className={styles.main} style={{
                justifyContent: "center"
            }}>
                <Outlet />
            </div >
            <Footer />
        </>
    )

}

export default MiniLayout;