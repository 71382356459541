import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { TextField } from '@mui/material';
import Modal from '@mui/material/Modal';

import styles from './../components/SignUpForm/SignIn.module.scss';

import OtpInput from '../components/OtpInput';
import Card from '../components/Card';
import Logo from '../components/Logo';

const SignUpForm: React.FC = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // State for form inputs
    const [formData, setFormData] = useState({
        companyName: '',
        mobileNumber: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    // Handler for form input change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Form submit handler
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        // You can handle sign-up logic here (API call, etc.)
    };

    return (
        <Card>
            <div className={styles.wrapper}>
                <form onSubmit={handleSubmit}>
                    <div className={styles.header}>
                        <Logo />
                        <h2>Create Account</h2>
                    </div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        type="email"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                        placeholder="Mobile number"
                        sx={{ marginBottom: '20px' }}
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        type="email"
                        value={formData.companyName}
                        onChange={handleChange}
                        placeholder="Company name (Optional)"
                        sx={{ marginBottom: '20px' }}
                    />
                    <div className={styles.footer}>
                        <Button type="submit" variant="contained">Sign Up</Button>
                        <div className={styles.bottomLink}>
                            Already have an account?
                            <NavLink to={"/"}>Sign in</NavLink>
                        </div>
                    </div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="otp-modal"
                        aria-describedby="otp-modal-verify login"
                    >
                        <OtpInput />
                    </Modal>
                </form>
            </div>
        </Card>
    );
};

export default SignUpForm;

