import {
    TextField,
    MenuItem,
    Button,
    Card,
    CardActions,
    Snackbar,
    SnackbarCloseReason,
} from '@mui/material'
import axios from 'axios'
import { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
// import GoogleAutocomplete from "libs/profile/GoogleAutocomplete";
import { fetchSession } from 'utils/loginUtils'

const vendorTypes = ['INTERIOR']

const API = process.env.REACT_APP_API
export function Profile() {
    const [formData, setFormData] = useState({
        companyName: '',
        email: '',
        city: '',
        mobileNumber: '',
        vendorType: '',
        vendorId: '',
    })
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState(false)

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason
    ) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }
    const updateVendor = async (data: any) => {
        return axios({
            url: API + 'vendor/upsert',
            method: 'post',
            data,
        })
    }
    const handleSubmit = async (e: React.FormEvent) => {
        try {
            e.preventDefault()
            // setLoader(true)
            const {
                companyName = '',
                mobileNumber = '',
                city = '',
                email = '',
                vendorType,
            } = formData
            const splittedPhone = mobileNumber.split("+")
            const { data: user } = await updateVendor({
                companyName,
                mobileNumber: splittedPhone.length > 1 ? splittedPhone[1] : splittedPhone[0],
                vendorType: vendorType.toUpperCase(),
                email,
                city,
            })
            localStorage.setItem('user', JSON.stringify(user))
            setLoader(false)
            setOpen(true)
        } catch {
            setLoader(false)
        }
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    // const handleSelectPlace = (location: google.maps.places.PlaceResult | null) => {
    //     console.log(location)
    //     if (location) {
    //         setFormData({
    //             ...formData,
    //             // location,
    //         });

    //     } else {
    //         console.log('No place selected');
    //     }
    // };

    useEffect(() => {
        const { session }: any = fetchSession()
        const { mobileNumber, vendorType, companyName, email, city, vendorId } =
            session
        setFormData({
            companyName,
            email,
            city,
            mobileNumber,
            vendorType,
            vendorId,
        })
    }, [])
    return (
        <Card sx={{ borderRadius: 4, p: 3, width: 350, margin: '0 auto' }}>
            <form onSubmit={handleSubmit}>
                <div>
                    <h2>Profile</h2>
                </div>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    placeholder="Company Name"
                    sx={{ marginBottom: 2 }}
                    onChange={handleChange}
                />
                <PhoneInput
                    placeholder="Enter phone number"
                    value={formData.mobileNumber}
                    inputStyle={{ marginBottom: '20px', width: '100%' }}
                    country={'in'}
                    preferredCountries={['in']}
                    specialLabel="Mobile number"
                    disabled
                />
                <TextField
                    variant="outlined"
                    fullWidth
                    type="email"
                    name="email"
                    value={formData.email}
                    placeholder="Email address"
                    sx={{ marginBottom: 2 }}
                    onChange={handleChange}
                />
                <TextField
                    select
                    label="Vendor"
                    variant="outlined"
                    name="vendorType"
                    value={formData.vendorType}
                    sx={{ marginBottom: 2 }}
                    fullWidth
                >
                    {vendorTypes.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={formData.city}
                    placeholder="City"
                    name="city"
                    sx={{ marginBottom: 2 }}
                    onChange={handleChange}
                />
                <CardActions sx={{ margin: '0 auto' }}>
                    <Button type="submit" variant="contained" disabled={loader}>
                        Update
                    </Button>
                </CardActions>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    message="Profile is updated succesfully"
                />
            </form>
        </Card>
    )
}
