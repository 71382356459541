import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';


import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import styles from "./MainLayout.module.scss"

const MainLayout = () => {
    return (
        <>  
            <NavBar/>
            <div className={styles.main}>
             <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default MainLayout;