import { Box } from "@mui/material"

import styles from "./Card.module.scss"

const Card = ({
    children
}: any) => {
    return (
        <Box>
            <div className={styles.card}>
                {children}
            </div>
        </Box>
    )
}

export default Card;