// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__zQfcO {
  padding: 40px 24px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #effaff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 310px;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.module.scss"],"names":[],"mappings":"AACA;EACI,kBAAA;EACA,mBAAA;EACA,wCAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAAJ","sourcesContent":["@import 'theme';\r\n.card{\r\n    padding:40px 24px;\r\n    border-radius: 16px;\r\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n    background:#effaff;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width:310px;   \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card_card__zQfcO`
};
export default ___CSS_LOADER_EXPORT___;
