import styles from "./Footer.module.scss"

const Footer = () => {
    return (
        <footer className={styles.footer}>
            All Rights Reserved 
        </footer>
    )
}

export default Footer