import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { UserState } from 'redux/types/userTypes';

const API = process.env.REACT_APP_API

const initialState: UserState = {
  user: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: any) => {
      state.user = action.payload;
    },
  },
});

export const updateVendor = async (data: any) => {
  return axios({
    url: API + "vendor/upsert",
    method: "post",
    data,
  })
}

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
