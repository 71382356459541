import {
    Card,
    CardMedia,
    CardContent,
    Checkbox,
    Stack,
    Chip,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import axios from 'axios'
import {
    useEffect,
    useMemo,
    useState,
    forwardRef,
    useImperativeHandle,
} from 'react'
import { fetchSession } from 'utils/loginUtils'

const API = process.env.REACT_APP_API

interface UserGridProps {
    isSelectionEnabled: boolean
    setSelectedImgs: React.Dispatch<React.SetStateAction<any[]>>
    selectedImgs?: any[]
}

const UserGrid = forwardRef(
    (
        { isSelectionEnabled, setSelectedImgs, selectedImgs }: UserGridProps,
        ref
    ) => {
        const [pics, setPics] = useState([])
        const { session }: any = fetchSession()
        const [tags, setTags] = useState([])

        const fetchVendorPics = async () => {
            try {
                const { data } = await axios({
                    url:
                        API +
                        'vendorPortfolioImages/list/v2/' +
                        session?.vendorId,
                    method: 'GET',
                })
                setPics(data)
            } catch {
                setPics([])
            }
        }

        const fetchTags = async () => {
            try {
                const { data } = await axios({
                    url: API + 'tags',
                    method: 'GET',
                })
                setTags(data)
            } catch {
                setTags([])
            }
        }

        useImperativeHandle(ref, () => ({
            fetchVendorPics,
            fetchTags,
        }))

        useEffect(() => {
            fetchVendorPics()
            fetchTags()
        }, [])

        const pictures = useMemo(() => {
            if (pics.length > 0 && tags.length > 0) {
                return pics.map((pic: any) => {
                    const tagsArray = pic.tagIds?.split(',') ?? []
                    console.log({ tagsArray })

                    pic.tags = tagsArray.map((tag: string) =>
                        tags.find((ta: any) => ta?.tagId?.toString() === tag)
                    )
                    return pic
                })
            }
        }, [pics, tags])

        const handleDelete = () => {
            console.info('You clicked the delete icon.');
        };

        console.log({ pictures, tags })
        return (
            <Grid container spacing={2}>
                {pictures?.map((item: any) => (
                    <Grid size={{ xs: 12, md: 4 }} key={item.imageId}>
                        <Card
                            sx={{ borderRadius: 2, p: 0, position: 'relative' }}
                        >
                            {isSelectionEnabled && (
                                <Checkbox
                                    checked={selectedImgs?.some(
                                        (img) => img.imageId === item.imageId
                                    )}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedImgs((prev) => [
                                                ...prev,
                                                item,
                                            ])
                                        } else {
                                            setSelectedImgs((prev) =>
                                                prev.filter(
                                                    (img) =>
                                                        img.imageId !==
                                                        item.imageId
                                                )
                                            )
                                        }
                                    }}
                                    sx={{
                                        position: 'absolute',
                                        top: 8,
                                        left: 8,
                                        color: 'white',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        ':hover': {
                                            backgroundColor:
                                                'rgba(0, 0, 0, 0.8)',
                                        },
                                        '&.Mui-checked': {
                                            color: 'primary.main',
                                        },
                                    }}
                                />
                            )}
                            <CardMedia
                                component="img"
                                image={item?.gcsUrl}
                                height="200"
                            />
                            <CardContent
                                sx={{
                                    pt: 0.5,
                                    pr: 0.5,
                                    pb: 0.5,
                                    pl: 0.5,
                                }}
                            >
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ flexWrap: 'wrap' }}
                                >
                                    {item?.tags?.map((tag: any) => {
                                        return (
                                            <div
                                                style={{
                                                    marginBottom: 4,
                                                }}
                                            >
                                                <Chip
                                                    label={tag?.tagName}
                                                    variant="outlined"
                                                    onDelete={handleDelete}
                                                    key={tag?.tagId}
                                                    size="small"
                                                />
                                            </div>
                                        )
                                    })}
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        )
    }
)

export default UserGrid
