// SignUpForm.tsx
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';


import styles from './../components/SignUpForm/SignIn.module.scss';

import OtpInput from '../components/OtpInput';
import { NavLink } from 'react-router-dom';
import Logo from '../components/Logo';
import Card from '../components/Card';
import {
  auth
} from "./../firebase";


const SignInForm: React.FC = () => {
  const [showOtpModal, setShowOTPModal] = React.useState(false);
  const [loader, showLoader] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setShowOTPModal(false);

  // State for form inputs
  const [formData, setFormData] = useState({
    mobileNumber: '',
  });

  const configureCaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recapthca-container', {
      'size': 'invisible',
      'callback': (response: any) => {
        console.log(response)
      },
      "expired-callback": () => {
        console.log("Response expired. Ask user to solve reCAPTCHA again.");
      },
      defaultCountry: "IN",
    });
  };

  // Handler for form input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    configureCaptcha()
  }, [])

  // Form submit handler
  const handleSubmit = async (e: React.FormEvent) => {
    try {
      showLoader(true)
      e.preventDefault();
      const appVerifier = window.recaptchaVerifier;
      const result = await signInWithPhoneNumber(auth, '+' + formData.mobileNumber, appVerifier)
      console.log("OTP has been sent", result);
      window.confirmationResult = result;
      setShowOTPModal(true);
      showLoader(false)
    } catch (err) {
      showLoader(false)
      console.error("Error: SMS not sent", err);
    }
  };

  console.log(formData.mobileNumber)

  return (
    <Card>
      <div className={styles.wrapper}>
        <form onSubmit={handleSubmit}>
          <div id="recapthca-container"></div>
          <div className={styles.header}>
            <Logo width="100" />
            <h2>Sign In</h2>
          </div>
          <PhoneInput
            placeholder='Enter phone number'
            value={formData.mobileNumber}
            inputStyle={{ marginBottom: '20px' }}
            country={"in"}
            preferredCountries={['in']}
            specialLabel="Mobile number"
            onChange={(newValue) => setFormData((prev) => ({
              ...prev,
              ["mobileNumber"]: newValue,
            }))}
          />
          <div className={styles.footer}>
            <Button type="submit" variant="contained" disabled={formData.mobileNumber.length != 12 || loader}>Sign In</Button>
            {/* <div className={styles.bottomLink}>
              Don't have an account?
              <NavLink to={"/signup"}>Create One</NavLink>
            </div> */}
          </div>
          <Modal
            open={showOtpModal}
            onClose={handleClose}
            aria-labelledby="otp-modal"
            aria-describedby="otp-modal-verify login"
          >
            <OtpInput />
          </Modal>
        </form>
      </div>
    </Card>
  );
};

export default SignInForm;