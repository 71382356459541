import { Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { UserCard, UploadPics, UserGrid } from '../libs/Dashboard'
import { useState, useRef } from 'react'
import AddTags from 'libs/Dashboard/AddTags'

export function Dashboard() {
    const [isSelectionEnabled, setIsSelectionEnabled] = useState(false)
    const [selectedImgs, setSelectedImgs] = useState<any[]>([])
    const userGridRef = useRef<any>(null)

    const refetchUserGridData = () => {
        if (userGridRef.current) {
            userGridRef.current.fetchVendorPics()
            userGridRef.current.fetchTags()
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
                <Grid size={{ xs: 12, md: 3 }}>
                    <UserCard />
                </Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: 1,
                            alignItems: { xs: 'center', md: 'end' },
                            justifyContent: { xs: 'center', md: 'end' },
                            gap: 1,
                        }}
                    >
                        <UploadPics />
                        <AddTags
                            setIsSelectionEnabled={setIsSelectionEnabled}
                            isSelectionEnabled={isSelectionEnabled}
                            selectedImgs={selectedImgs}
                            setSelectedImgs={setSelectedImgs}
                            refetchUserGridData={refetchUserGridData}
                        />
                    </Box>
                    <UserGrid
                        ref={userGridRef}
                        isSelectionEnabled={isSelectionEnabled}
                        setSelectedImgs={setSelectedImgs}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
