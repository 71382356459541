import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';


import './App.css';
import theme from "./theme"
import Routes from "./Router"


function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
