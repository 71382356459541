import React, { useState } from 'react'
import { Box, Button, TextField, useTheme } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import useMediaQuery from '@mui/material/useMediaQuery'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { fetchSession } from 'utils/loginUtils'

const API = process.env.REACT_APP_API

const UploadPics = () => {
    const { session }: any = fetchSession()
    const [open, setOpen] = useState(false)
    const [loader, setLoader] = useState(false)
    const [files, setFiles] = useState<any>(null)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const navigate = useNavigate()

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const uploadVendorPics = async (e: any) => {
        try {
            e.preventDefault()
            setLoader(false)
            const formData = new FormData()
            formData.append('imageFiles', files[0])
            const config = {
                headers: { 'Content-Type': 'multipart/form-data' },
            }
            await axios.post(
                API + 'vendor/image/upload/' + session?.vendorId,
                formData,
                config
            )
            setLoader(false)
            setOpen(false)
            navigate(0)
        } catch {
            setLoader(false)
        }
    }
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFiles(e.target.files)
        console.log(e.target.files)
    }
    return (
        <>
            <Button variant="contained" onClick={handleClickOpen}>
                Upload Pics
            </Button>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullScreen={fullScreen}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Upload Pics
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    dividers
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 1,
                        alignItems: 'center',
                    }}
                >
                    <TextField
                        type="file"
                        name="imageFiles"
                        inputProps={{
                            multiple: true,
                        }}
                        onChange={handleFileChange}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={uploadVendorPics}
                        disabled={loader}
                    >
                        Upload
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UploadPics
