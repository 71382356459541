import React, { useState, useRef } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { setUser, updateVendor } from "./../../redux/slicers/userSlice"
import { useDispatch } from 'react-redux';

const OtpInput: React.FC = () => {
  const dispatch = useDispatch()
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)

  const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
  // Handle change for each digit input
  const handleChange = (value: string, index: number) => {
    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;

    // Focus next field
    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }

    setOtp(newOtp);
  };

  // Handle backspace and navigation on backspace
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  // Submit OTP
  const onSubmitOTP = async (e: React.FormEvent) => {
    try {
      setLoader(true)
      e.preventDefault();
      const otpValue = otp.join('');
      if (otpValue.length < 6) {
        alert('Please enter the full 6-digit OTP.');
        return;
      }
      const result = await window.confirmationResult.confirm(otpValue)
      const splittedPhone = result.user.phoneNumber.split("+")

      const { data: user } = await updateVendor({
        "companyName": "",
        "mobileNumber": splittedPhone.length > 1 ? splittedPhone[1] : splittedPhone[0],
        "vendorType": "INTERIOR",
        "city": "",
        "email": ""
      })
      localStorage.setItem("user", JSON.stringify(user))
      dispatch(setUser(user))
      setLoader(false)
      navigate('/dashboard');
    } catch (e) {
      setLoader(false)
      console.log(e)
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
        height: '200px',
        margin: '20% auto',
      }}
    >
      <Typography variant="h5" gutterBottom>
        Enter OTP
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        {otp.map((digit, index) => (
          <TextField
            key={index}
            inputRef={(ref) => (inputRefs.current[index] = ref)}
            value={digit}
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            inputProps={{ maxLength: 1, style: { textAlign: 'center', fontSize: '24px' } }}
            type="number"
            sx={{ width: '40px', margin: '0 5px' }}
          />
        ))}
      </Box>
      <Button variant="contained" color="primary" type="button" disabled={loader} onClick={onSubmitOTP}>
        Verify OTP
      </Button>
    </Box>
  );
};

export default OtpInput;
