import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAaYTnw9jfi7UKrcbSIoy--sGpU1m8-9cY",
  authDomain: "brosky-reactnative.firebaseapp.com",
  projectId: "brosky-reactnative",
  storageBucket: "brosky-reactnative.appspot.com",
  messagingSenderId: "1066401760475",
  appId: "1:1066401760475:web:13f241c2de742e5a7538fe",
  measurementId: "G-KZMXMJL6TS"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
export default app;