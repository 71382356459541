import React, { useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'

const API = process.env.REACT_APP_API

interface AddTagsProps {
    setIsSelectionEnabled: (enabled: boolean) => void
    isSelectionEnabled: boolean
    selectedImgs: any[]
    setSelectedImgs: (imgs: any[]) => void
    refetchUserGridData: () => void
}

const AddTags: React.FC<AddTagsProps> = ({
    setIsSelectionEnabled,
    isSelectionEnabled,
    selectedImgs,
    setSelectedImgs,
    refetchUserGridData,
}) => {
    const onSelectHandle = () => {
        setIsSelectionEnabled(true)
    }
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const [isAddTagOpen, setIsAddTagOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [selectedTags, setSelectedTags] = useState<any[]>([])
    const [tagList, setTagList] = useState<any[]>([])

    console.log({ tagList, error, selectedTags, selectedImgs })

    useEffect(() => {
        onLoadtags()

        return () => {
            setTagList([])
            setSelectedTags([])
        }
    }, [])

    useEffect(() => {
        setSelectedTags([])
    }, [isSelectionEnabled])

    const onLoadtags = async () => {
        try {
            const response = await axios.get(`${API}/tags`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            setTagList(response.data)
        } catch {
            setError(true)
        }
    }

    const onAddTagHandle = async () => {
        try {
            if (selectedImgs.length === 0) {
                return
            }
            setIsLoading(true)

            const url = `${API}/image-tags/add?imageIds=${selectedImgs.map((img) => img.imageId).join(',')}`

            const payLoad = selectedTags.map((tagId) => ({
                tagId,
            }))

            const response = await axios.post(url, payLoad, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            console.log({ response })
            refetchUserGridData()
        } catch {
            setError(true)
        } finally {
            setIsAddTagOpen(false)
            setIsSelectionEnabled(false)
            setIsLoading(false)
            setSelectedImgs([])
        }
    }

    return (
        <>
            {!isSelectionEnabled && (
                <Button variant="contained" onClick={onSelectHandle}>
                    Select Images
                </Button>
            )}

            {selectedImgs.length > 0 && isSelectionEnabled && (
                <Button
                    variant="contained"
                    onClick={() => {
                        setIsAddTagOpen(true)
                    }}
                    color="primary"
                >
                    Add Tags
                </Button>
            )}

            {isSelectionEnabled && (
                <Button
                    variant="contained"
                    onClick={() => setIsSelectionEnabled(false)}
                    color="error"
                >
                    Cancel
                </Button>
            )}
            <Dialog
                open={isAddTagOpen}
                onClose={() => setIsAddTagOpen(false)}
                fullScreen={fullScreen}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Add Tags
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setIsAddTagOpen(false)}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    dividers
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 1,
                        alignItems: 'center',
                    }}
                >
                    <TextField
                        select
                        SelectProps={{
                            multiple: true,
                            value: selectedTags,
                            onChange: (event) =>
                                setSelectedTags(event.target.value as any[]),
                            MenuProps: {
                                PaperProps: {
                                    style: {
                                        minWidth: 250,
                                    },
                                },
                            },
                        }}
                        style={{ minWidth: 250 }}
                        label="Select Tags"
                        variant="outlined"
                        fullWidth
                    >
                        {tagList.map((tag) => (
                            <MenuItem key={tag.tagId} value={tag.tagId}>
                                {tag.tagName}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={onAddTagHandle}
                        disabled={isLoading}
                    >
                        Add
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddTags
