import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ReactNode, useEffect, useState } from 'react';


import { Avatar, Box, Link } from '@mui/material';
import { blueGrey, green } from '@mui/material/colors';
import { fetchSession } from 'utils/loginUtils';

interface CardProps {
  title?: string;
  content?: string | ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  className?: string; // For custom styling
}

const UserCard: React.FC<CardProps> = ({ className }) => {
  const [vendor, setVendor] = useState({
    companyName: '',
    email: '',
    city: '',
    mobileNumber: '',
    vendorType: '',
    vendorId: ""
  });
  useEffect(() => {
    const { session }: any = fetchSession()
    const { mobileNumber, vendorType, companyName, email, city, vendorId } = session;
    setVendor({
      companyName,
      email,
      city,
      mobileNumber,
      vendorType,
      vendorId
    })
  }, [])
  return (
    <Card sx={{ borderRadius: 4, p: 3, width: 275, height: 180 }} className={className}>
      <Box
        sx={{
          mb: 4,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <Avatar
            sx={{ bgcolor: blueGrey[600], width: 60, height: 60, mr: 2 }}
          >
            {vendor?.companyName?.split("")[0]}
          </Avatar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start"
            }}
          >
            <Typography variant="body1" sx={{ mb: 0.5, fontWeight: 500 }}>

              {vendor?.companyName}
            </Typography>
            <Link
              href={"tel:" + vendor?.mobileNumber + ""}
              underline="always"
              variant="body1"
              color={green[500]}
              sx={{ fontWeight: 500 }}
            >
              + {vendor?.mobileNumber}
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
        </Box>
      </Box>
      <CardContent sx={{ p: 0, mb: 0 }}>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: 1 }}
        >
          Location
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          {vendor?.city}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default UserCard;
