// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignIn_bottomLink__3E6zJ {
  margin-top: 10px;
}
.SignIn_bottomLink__3E6zJ a {
  margin-left: 4px;
}

.SignIn_wrapper__K4pMw {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: auto;
}
.SignIn_wrapper__K4pMw form {
  width: 100%;
}
.SignIn_wrapper__K4pMw .SignIn_header__dYAd9, .SignIn_wrapper__K4pMw .SignIn_footer__l1kQ2 {
  text-align: center;
}
.SignIn_wrapper__K4pMw button {
  width: 200px;
  padding: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/SignUpForm/SignIn.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAAI;EACI,gBAAA;AAER;;AACA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,UAAA;AAEJ;AAAI;EACI,WAAA;AAER;AACI;EACI,kBAAA;AACR;AAEI;EACI,YAAA;EACA,YAAA;AAAR","sourcesContent":[".bottomLink{\r\n    margin-top: 10px;\r\n    a{\r\n        margin-left: 4px;\r\n    }\r\n}\r\n.wrapper{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    flex: auto;\r\n    // background-image: linear-gradient(180deg,#00488d 0%,#007ca9 100%);\r\n    form{\r\n        width: 100%;\r\n    }\r\n\r\n    .header, .footer{\r\n        text-align: center;\r\n    }\r\n\r\n    button{\r\n        width: 200px;\r\n        padding: 8px;\r\n    }\r\n}\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottomLink": `SignIn_bottomLink__3E6zJ`,
	"wrapper": `SignIn_wrapper__K4pMw`,
	"header": `SignIn_header__dYAd9`,
	"footer": `SignIn_footer__l1kQ2`
};
export default ___CSS_LOADER_EXPORT___;
