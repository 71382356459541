import { Navigate, useRoutes } from "react-router-dom";
import {
  SignUpForm,
  SignInForm
} from "./pages"
import { fetchSession } from "utils/loginUtils";
import MiniLayout from "./layouts/MiniLayout";
import MainLayout from "./layouts/MainLayout";
import { Dashboard } from "pages/Dashboard";
import { Profile } from "pages/Profile";
const Routes = () => {
  const { isSessionValid }: any = fetchSession()
  const element = useRoutes([
    {
      element: isSessionValid ? <Navigate to="/dashboard" /> : <MiniLayout />,
      children: [
        { path: "/signup", element: <SignUpForm /> },
        {
          path: "/",
          element: <SignInForm />,
          // children: [
          //   {
          //     path: "messages",
          //     element: <DashboardMessages />,
          //   },
          //   { path: "tasks", element: <DashboardTasks /> },
          // ],
        },
      ]
    },
    {
      element: isSessionValid ? <MainLayout /> : <Navigate to="/" />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/profile",
          element: <Profile />,
        }
      ]
    }

  ]);

  return element;
}

export default Routes